import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import Invoice from '@app/components/public/Invoice/Invoice';
import { InvoiceData, getInvoicesData } from '@app/api/invoices.api';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const PublicInfoPage: React.FC = () => {
  const { t } = useTranslation();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<InvoiceData>();

  const onFinish = async (values: { invoiceNumber: string }) => {
    setLoading(true);

    getInvoicesData(values)
      .then((res) => {
        console.log(res);
        setInvoice(res);
        setLoading(false);
      })
      .catch((e) => notificationController.error({ message: e.message }))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <PageTitle>{t('public.nav.payInvoice.title')}</PageTitle>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={24} xl={24}>
          <BaseCard id="step-form" title={t('public.nav.payInvoice.title')} padding="1.25rem">
            <BaseButtonsForm
              {...formItemLayout}
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              name="validateForm"
              footer={
                <BaseButtonsForm.Item>
                  <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                    {t('common.proceed')}
                  </BaseButton>
                </BaseButtonsForm.Item>
              }
              onFinish={onFinish}
            >
              <BaseButtonsForm.Item
                name="invoiceNumber"
                label={t('public.nav.payInvoice.input.invoiceNumber.label')}
                rules={[{ required: true, message: t('public.nav.payInvoice.input.invoiceNumber.validationMessage') }]}
              >
                <BaseInput placeholder={t('public.nav.payInvoice.input.invoiceNumber.placeholder')} />
              </BaseButtonsForm.Item>
            </BaseButtonsForm>
            {invoice && <Invoice invoice={invoice} />}
          </BaseCard>
        </BaseCol>
      </BaseRow>
    </>
  );
};

export default PublicInfoPage;
