import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import * as S from './StatesItem.styles';

const selectOptions = (states: any[]) =>
  states.map((state) => (
    <Option key={state.name} value={state.isoCode}>
      <S.CountriesSpace align="center">{state.name}</S.CountriesSpace>
    </Option>
  ));

export const StatesItem: React.FC<{ states: any; nameTitle?: string; handleStateChange?: any; readOnly?: boolean }> = ({
  states,
  nameTitle,
  handleStateChange,
  readOnly,
}) => {
  const { t } = useTranslation();

  return (
    <BaseButtonsForm.Item
      name={nameTitle || 'state'}
      rules={[
        {
          required: true,
          message: t('profile.nav.register.requiredState'),
        },
      ]}
      label={
        nameTitle
          ? nameTitle === 'stateOfOrigin'
            ? t('common.stateOfOrigin')
            : t('common.stateOfResidence')
          : t('common.state')
      }
    >
      <BaseSelect
        showSearch
        disabled={states.length === 0 || !!readOnly}
        placeholder={t('profile.nav.register.requiredState')}
        onChange={(e) => (handleStateChange ? handleStateChange(e) : null)}
        filterOption={(input, option) => option?.value.toLowerCase().includes(input.toLowerCase())}
      >
        {states && selectOptions(states)}
      </BaseSelect>
    </BaseButtonsForm.Item>
  );
};
