import React from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import * as S from './PhoneItem.styles';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';

interface PhoneItemsProps {
  required?: boolean;
  onClick?: () => void;
  verified?: boolean;
  readOnly?: boolean;
}

export const PhoneItem: React.FC<PhoneItemsProps> = ({ required, onClick, verified, readOnly }) => {
  const { t } = useTranslation();

  return (
    <BaseButtonsForm.Item
      name="phoneNumber"
      $isSuccess={verified}
      $successText={t('profile.nav.personalInfo.verified')}
      label={t('common.phone')}
      rules={[
        { required: true, len: 11, message: t('profile.nav.register.requiredPhoneNumber') },
        () => ({
          validator(_, value) {
            if (!value || isValidPhoneNumber(value, 'NG')) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(t('profile.nav.personalInfo.wrongNumber')));
          },
        }),
      ]}
    >
      <BaseInput readOnly={!!readOnly} disabled={verified} className="ant-input" onClick={onClick} />
      {/* <S.PhoneNumberInput disabled={verified} className="ant-input" onClick={onClick} /> */}
    </BaseButtonsForm.Item>
  );
};
