import { httpApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';

export interface MdaData {
  name: string;
  id: number;
}

export interface MdaResponseData {
  docs: MdaData[];
}

export const getMdasData = (): Promise<MdaData[] | undefined> => {
  return httpApi.get<MdaResponseData>('mdas').then(({ data }) => data.docs);
};
