import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

export const AddressNewItem: React.FC<{ readOnly?: boolean }> = ({ readOnly }) => {
  const { t } = useTranslation();

  return (
    <BaseButtonsForm.Item
      name={`address`}
      rules={[
        {
          required: true,
          message: t('profile.nav.register.requiredAddress'),
        },
      ]}
      label={`${t('common.address')}`}
    >
      <BaseInput readOnly={!!readOnly} />
    </BaseButtonsForm.Item>
  );
};
