import React, { startTransition, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import * as S from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { AppleOutlined, AndroidOutlined } from '@ant-design/icons';
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import { BaseTag } from '@app/components/common/BaseTag/BaseTag';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { MdaData, getMdasData } from '@app/api/mdas.api';
import { RevenueData, getRevenuesData } from '@app/api/revenues.api';
import { useNavigate } from 'react-router-dom';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const InvoiceGenerationPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [mdas, setMdas] = useState<MdaData[]>();
  const [revenues, setRevenues] = useState<RevenueData[]>();

  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getMdasData()
      .then((res) => setMdas(res))
      .catch((e) => notificationController.error({ message: e.message }));
  }, []);

  useEffect(() => {
    getRevenuesData()
      .then((res) => setRevenues(res))
      .catch((e) => notificationController.error({ message: e.message }));
  }, []);

  const onFinish = async (values = {}) => {
    console.log(values);
    setLoading(true);
    startTransition(() => {
      navigate('/public/invoice-details', { state: values });
      setLoading(false);
    });
  };

  const items = (props: { revenues?: RevenueData[]; mdas?: MdaData[]; hide: boolean; category: string }) => {
    return (
      <>
        <BaseButtonsForm
          {...formItemLayout}
          isFieldsChanged={isFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          name="validateForm"
          footer={
            <BaseButtonsForm.Item>
              <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                {t('common.proceed')}
              </BaseButton>
            </BaseButtonsForm.Item>
          }
          onFinish={onFinish}
          initialValues={{ category: props.category }}
        >
          <BaseButtonsForm.Item
            name="agency"
            label={t('public.nav.invoice.input.agency.label')}
            hasFeedback
            hidden={props.hide}
            rules={[{ required: !props.hide, message: t('public.nav.invoice.input.agency.validationMessage') }]}
          >
            <BaseSelect
              width={'100%'}
              options={props?.mdas?.map((mda) => ({
                value: mda.id,
                label: mda.name,
              }))}
              showArrow
              allowClear
              showSearch
              loading={false}
            />
          </BaseButtonsForm.Item>

          <BaseButtonsForm.Item
            name="revenue"
            label={t('public.nav.invoice.input.taxItem.label')}
            rules={[{ required: true, message: t('public.nav.invoice.input.taxItem.validationMessage') }]}
          >
            <BaseSelect
              width={'100%'}
              options={props?.revenues?.map((revenue) => ({
                value: revenue.id,
                label: revenue.name,
              }))}
              showArrow
              allowClear
              showSearch
              loading={false}
            />
          </BaseButtonsForm.Item>

          <BaseButtonsForm.Item name="category" hidden={true}>
            <BaseInput />
          </BaseButtonsForm.Item>
        </BaseButtonsForm>
      </>
    );
  };

  return (
    <>
      <PageTitle>{t('public.nav.invoice.title')}</PageTitle>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={24} xl={24}>
          <BaseCard id="step-form" title={t('public.nav.invoice.title')} padding="1.25rem">
            <BaseTabs
              defaultActiveKey="2"
              items={[
                {
                  key: '1',
                  label: <span>{t('public.nav.invoice.buttons.individual')}</span>,
                  children: items({ revenues, mdas, hide: true, category: 'individual' }),
                },
                {
                  key: '2',
                  label: <span>{t('public.nav.invoice.buttons.corporate')}</span>,
                  children: items({ revenues, mdas, hide: true, category: 'corporate' }),
                },
                {
                  key: '3',
                  label: <span>{t('public.nav.invoice.buttons.agency')}</span>,
                  children: items({ revenues, mdas, hide: false, category: 'agency' }),
                },
              ]}
            />
          </BaseCard>
        </BaseCol>
      </BaseRow>
    </>
  );
};

export default InvoiceGenerationPage;
