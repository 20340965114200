import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readToken } from '@app/services/localStorage.service';

export const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export interface ApiErrorData {
  message?: string;
}

http.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

  return config;
});

http.interceptors.response.use(undefined, (error: AxiosError | any) => {
  throw new ApiError<ApiErrorData[]>(error.response?.data?.errors[0].message || error.message, [
    error.response?.data?.errors,
  ]);
});

export const httpApi = http;
