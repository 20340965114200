import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { FullNameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/FullNameItem/FullNameItem';
import { PayerIdItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/PayerIdItem/PayerIdItem';
import { NicknameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/NicknameItem/NicknameItem';
import { SexItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/SexItem/SexItem';
import { BirthdayItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/BirthdayItem/BirthdayItem';
import { LanguageItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LanguageItem/LanguageItem';
import { PhoneItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/PhoneItem/PhoneItem';
import { EmailItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/EmailItem/EmailItem';
import { CountriesItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/CountriesItem/CountriesItem';
import { CitiesItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/CitiesItem/CitiesItem';
import { ZipcodeItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/ZipcodeItem/ZipcodeItem';
import { AddressItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/AddressItem/AddressItem';
import { WebsiteItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/WebsiteItem/WebsiteItem';
import { SocialLinksItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/SocialLinksItem/SocialLinksItem';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Dates } from '@app/constants/Dates';
import { notificationController } from '@app/controllers/notificationController';
import { PaymentCard } from '@app/interfaces/interfaces';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { TinItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/TinItem/TinItem';
import { AddressNewItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/AddressNewItem/AddressNewItem';
import { StatesItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/StatesItem/StatesItem';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { LgasItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LgasItem/LgasItem';
import { ExternalRefItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/ExternalRefItem/ExternalRefItem';
import { State, IState, City, ICity } from 'country-state-city';
import { useLocation } from 'react-router-dom';
import { createInvoice } from '@app/api/invoices.api';
import { AmountItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/AmountItem/AmountItem';
import { RevenueItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/RevenueItem/RevenueItem';

interface PersonalInfoFormValues {
  fullName: string;
  state: string;
  address: string;
  phone: string;
  email: string;
  lga: string;
  extReferencce: string;
  tin: string;
  amount: string;
  payerID: string;
  category: string;
}

const initialPersonalInfoValues: PersonalInfoFormValues = {
  fullName: '',
  phone: '',
  email: '',
  state: '',
  lga: '',
  address: '',
  extReferencce: '',
  tin: '',
  amount: '',
  payerID: '',
  category: '',
};

export const InvoiceConfirmation: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const location = useLocation();
  const {
    id,
    profileCode,
    tin,
    category,
    fullName,
    email,
    phoneNumber,
    revenueHead,
    stateOfResidence,
    lgaOfResidence,
    address,
    tenants,
  } = location.state;
  // console.log(location.state);

  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  const [form] = BaseButtonsForm.useForm();

  const handleStateChange = (value: string) => {
    const countryCode = 'NG';
    const cityList = City.getCitiesOfState(countryCode, value);
    setCities(cityList);
  };

  useEffect(() => {
    const countryCode = 'NG';
    const statesList = State.getStatesOfCountry(countryCode);
    setStates(statesList);
    initialPersonalInfoValues.state = stateOfResidence;
    initialPersonalInfoValues.lga = lgaOfResidence;
    initialPersonalInfoValues.address = address;
    initialPersonalInfoValues.fullName = fullName;
    initialPersonalInfoValues.email = email;
    initialPersonalInfoValues.phone = phoneNumber;
    initialPersonalInfoValues.tin = tin;
    initialPersonalInfoValues.category = category;
    console.log(initialPersonalInfoValues);
  }, [address, category, email, fullName, lgaOfResidence, phoneNumber, profileCode, setStates, stateOfResidence, tin]);

  const { t } = useTranslation();

  const onFinish = useCallback(
    (values: PaymentCard) => {
      // todo dispatch an action here
      setLoading(true);
      // createInvoice();
    },
    [t],
  );

  return (
    <BaseCard>
      <BaseButtonsForm
        form={form}
        name="payerProfile"
        loading={isLoading}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
        onFieldsChange={() => setFieldsChanged(true)}
        onFinish={onFinish}
        initialValues={initialPersonalInfoValues}
        footer={
          <BaseButtonsForm.Item>
            <BaseButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.confirm')}
            </BaseButton>
          </BaseButtonsForm.Item>
        }
      >
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
          <BaseCol xs={24} md={12}>
            <PayerIdItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <RevenueItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <FullNameItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <PhoneItem verified />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <EmailItem verified />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <TinItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <StatesItem states={states} nameTitle="state" handleStateChange={handleStateChange} />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <LgasItem cities={cities} />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <AddressNewItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <AmountItem />
          </BaseCol>
        </BaseRow>
      </BaseButtonsForm>
    </BaseCard>
  );
};
