import React from 'react';
import {
  DashboardOutlined,
  FormOutlined,
  LayoutOutlined,
  LineChartOutlined,
  TableOutlined,
  UserOutlined,
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.medical-dashboard',
    key: 'medical-dashboard',
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'common.dataTables',
    key: 'invoices',
    url: '/data-tables',
    icon: <TableOutlined />,
  },
  {
    title: 'common.dataTables',
    key: 'payments',
    url: '/data-tables',
    icon: <TableOutlined />,
  },
  {
    title: 'common.charts',
    key: 'taxProfiles',
    url: '/charts',
    icon: <LineChartOutlined />,
  },
];
