import React, { Component } from 'react';
import styled from 'styled-components';
import { baseInvoice } from './_Invoice';

export interface LineItemProps {
  id?: string;
  index?: number;
  name?: string;
  description?: string;
  quantity?: any | number;
  price?: any | number;
  currencyFormatter?: (amount: number) => string;
}

const LineItemComponent = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(2, 5fr) 2fr repeat(2, 3fr) 2fr;

  & > div {
    var(--flex-center);
    padding: calc(var(--rhythm)/2);
    border-left: 1px solid var(--tableBorderColor);
    width: 100%;
    line-height: var(--rhythm);
    min-width: var(--rhythm);
    &:first-of-type {
      padding: calc(var(--rhythm)/2) 0;
      border-left: none;
    }
    &:last-of-type {
      padding: calc(var(--rhythm)/2) 0;
    }
    &:first-of-type,
    &:nth-child(4),
    &:last-of-type {
      text-align: center;
    }
  }
`;

const Currency = styled.div`
  text-align: right;
  word-break: break-word;
`;

class LineItem extends Component<LineItemProps> {
  render() {
    const { index, name, description, quantity, price } = this.props;

    return (
      <LineItemComponent>
        <div>{index && index + 1}</div>
        <div>{name}</div>
        <div>{description}</div>
        <div>{quantity}</div>
        <Currency>{this.props?.currencyFormatter && this.props?.currencyFormatter(Number(price))}</Currency>
        <Currency>
          {this.props?.currencyFormatter && this.props?.currencyFormatter(Number(quantity) * Number(price))}
        </Currency>
      </LineItemComponent>
    );
  }
}

export default LineItem;
