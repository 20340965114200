import React from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SiderLogo } from '../SiderLogo';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const DesktopHeader: React.FC = ({}) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);

  const leftSide = (
    <>
      <SiderLogo isSiderCollapsed={false} toggleSider={() => true} />

      <BaseCol>
        <span>
          <Link to={'/'}>
            <BaseButton type="default">Dashboard</BaseButton>
          </Link>
        </span>
      </BaseCol>
      <BaseCol>
        <span>
          <Link to={'pay-invoice'}>
            <BaseButton type="text">{t('public.nav.home.buttons.payment')}</BaseButton>
          </Link>
        </span>
      </BaseCol>
      <BaseCol>
        <span>
          <Link to={'generate-invoices'}>
            <BaseButton type="text">{t('public.nav.home.buttons.invoice')}</BaseButton>
          </Link>
        </span>
      </BaseCol>
      <BaseCol>
        <span>
          <Link to={'generate-tin'}>
            <BaseButton type="text">{t('public.nav.home.buttons.tin')}</BaseButton>
          </Link>
        </span>
      </BaseCol>
      {user ? null : (
        <>
          <BaseCol>
            <span>
              <Link to={'register'}>
                <BaseButton type="text">{t('public.nav.home.buttons.register')}</BaseButton>
              </Link>
            </span>
          </BaseCol>
          <BaseCol>
            <span>
              <Link to={'login'}>
                <BaseButton type="text">{t('public.nav.home.buttons.login')}</BaseButton>
              </Link>
            </span>
          </BaseCol>
        </>
      )}
    </>
  );

  return (
    <BaseRow justify="space-between" align="middle">
      {leftSide}
      {user && (
        <S.ProfileColumn xl={8} xxl={7}>
          <BaseRow align="middle" justify="end" gutter={[5, 5]}>
            <BaseCol>
              <ProfileDropdown />
            </BaseCol>
          </BaseRow>
        </S.ProfileColumn>
      )}
    </BaseRow>
  );
};
