import { httpApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';

interface TaxPayer {
  id: number;
  profileCode: string;
  tin: string | null;
  category: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  stateOfOrigin: string | null;
  stateOfResidence: string | null;
  lgaOfResidence: string | null;
  address: string | null;
  tenants: Tenant[];
  updatedAt: string;
  createdAt: string;
}

interface RevenueHead {
  id: number;
  name: string;
  tenant: number;
  mda: number;
  updatedAt: string;
  createdAt: string;
}

interface Tenant {
  id?: string;
  tenant?: string;
  roles: string | null;
}

interface TenantMain {
  id: number;
  name: string;
  updatedAt: string;
  createdAt: string;
}

export interface InvoiceData {
  id: number;
  invoiceNumber: string;
  amount: string;
  status: string;
  dueDate: string;
  taxProfile: TaxPayer;
  revenueHead: RevenueHead;
  tenant: TenantMain;
  updatedAt: string;
  createdAt: string;
}

export interface InvoiceRequestData {
  amount: string;
  revenueHead: number;
  mdas: number;
  fullName: string;
  gender: string;
  category: string;
  phoneNumber: string;
  email: string;
  stateOfResidence?: string;
  LgaOfResidence?: string;
  address?: string;
  tenant: number;
}

export interface InvoiceResponseData {
  invoice?: InvoiceData;
  doc?: InvoiceData;
}

export interface PaymentRequest {
  email: string;
  amount: string;
}

export interface PaymentResponseData {
  data: {
    authorization_url: string;
    reference: string;
  };
}

interface Payment {
  invoiceNumber: number;
  transactionRef: string;
  amount: string;
  paymentMethod: string;
  tenants: Tenant[];
}

export interface PaymentResponse {
  doc: undefined;
}

export interface Pagination {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: null;
  page: number;
  pagingCounter: number;
  prevPage: null;
  totalDocs: number;
  totalPages: number;
}

export interface AllInvoiceResponseData extends Pagination {
  docs: InvoiceData[];
}

export const getAllInvoicesData = (pagination?: Partial<Pagination>): Promise<AllInvoiceResponseData> => {
  return httpApi.get<AllInvoiceResponseData>(`invoices?${pagination}`).then(({ data }) => data);
};

export const getInvoicesData = ({ invoiceNumber }: { invoiceNumber: string }): Promise<InvoiceData | undefined> => {
  return httpApi.get<InvoiceResponseData>(`invoices/invoice-number/${invoiceNumber}`).then(({ data }) => data.invoice);
};

export const createInvoice = (invoiceData: InvoiceRequestData): Promise<InvoiceData | undefined> => {
  console.log(invoiceData);
  return httpApi.post<InvoiceResponseData>(`invoices`, { ...invoiceData }).then(({ data }) => data.doc);
};

export const createPayment = (paymentData: Payment): Promise<PaymentResponse> => {
  return httpApi.post<PaymentResponse>('payments', { ...paymentData }).then(({ data }) => {
    return data;
  });
};

export const payInvoice = (paymentData: PaymentRequest): Promise<PaymentResponseData> => {
  return httpApi.post<string>('payments/paystack/payment', { ...paymentData }).then(({ data }) => {
    console.log(JSON.parse(data));
    return JSON.parse(data);
  });
};
