import React, { Component, useState } from 'react';
import styled from 'styled-components';
import LineItems from './LineItems';
import { LineItemProps } from './LineItem';
import { baseInvoice } from './_Invoice';
import logo from 'assets/logo-dark.png';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { PaymentResponseData, createPayment, payInvoice } from '@app/api/invoices.api';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';

interface Address {
  address: string;
  zip_code: string;
  state: string;
  country: string;
  city: string;
}

interface State {
  taxRate: number;
  dueDate: string;
  createdAt: string;
  invoiceNumber: string;
  profileCode: string;
  fullName: string;
  email: string;
  lineItems: LineItemProps[];
}

const StyledInvoice = styled.div`
  ${baseInvoice}
  max-width: var(--invoiceMaxWidth);
  margin: calc(var(--rhythm) * 2) auto;
  border: 1px solid var(--tableBorderColor);
  padding: calc(2 * var(--rhythm));
  border-radius: var(--borderRadius);
  background: var(--bgColor);
`;

const Brand = styled.div`
  margin-bottom: calc(var(--rhythm) * 2);
  .logo {
    display: inline-block;
    border-radius: var(--borderRadius);
    width: 48px;
    height: 48px;
  }
`;

const Addresses = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  text-align: left;
  .from,
  .to {
    padding: var(--rhythm);
    border-radius: var(--borderRadius);
    width: 100%;
    max-width: calc(var(--invoiceMaxWidth) / 3);
  }
  .from {
    display: block;
    font-style: normal;
    margin-right: calc(var(--rhythm) / 2);
    background: #efefef;
    border: 1px solid var(--tableBorderColor);
  }
  .to {
    display: block;
    margin-left: calc(var(--rhythm) / 2);
    padding: 0;
    .value {
      text-align: right;
    }
  }
  .address {
    display: block;
  }
`;

const ValueTable = styled.div`
  margin: 0 0 0 auto;
  border: 1px solid var(--tableBorderColor);
  border-radius: var(--borderRadius);
  overflow: hidden;
  width: 100%;
  min-width: calc(var(--invoiceMaxWidth) / 3);
  max-width: calc(var(--invoiceMaxWidth) / 3);
`;

const TotalContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 20px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: 1px solid var(--tableBorderColor);
  &:last-of-type {
    border-bottom: none;
  }
  & > div {
    padding: calc(var(--rhythm) / 2);
  }
  & > .label {
    var(--flex-center);
    justify-content: flex-start;
    font-weight: bold;
  }
`;

const Pay = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: calc(var(--rhythm) * 1) 0 var(--rhythm) var(--rhythm);
  .payNow {
    display: block;
    padding: var(--rhythm) calc(var(--rhythm) * 2);
    background: lightyellow;
    border-radius: var(--borderRadius);
    border: 1px solid #444;
  }
`;

const Footer = styled.div`
  ${baseInvoice}
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  .comments {
    padding: var(--rhythm);
    border-radius: var(--borderRadius);
    h4,
    h5 {
      margin-top: 0;
      margin-bottom: calc(var(--rhythm) / 2);
    }
  }
  .comments {
    text-align: left;
    background: #eee;
  }
  .closing {
    padding-top: var(--rhythm);
  }
`;

// eslint-disable-next-line @typescript-eslint/ban-types
const Invoice: React.FC<{ invoice?: any }> = ({ invoice }) => {
  const [isLoading, setLoading] = useState(false);

  const locale = 'en-NG';
  const currency = 'NGN';
  console.log(invoice);
  const state: State = {
    taxRate: 1.0,
    dueDate: new Date(invoice.dueDate).toDateString(),
    createdAt: new Date(invoice.updatedAt).toDateString(),
    invoiceNumber: invoice.invoiceNumber,
    profileCode: invoice.taxProfile.profileCode,
    fullName: invoice.taxProfile.fullName,
    email: invoice.taxProfile.email,
    lineItems: [
      {
        id: '1',
        name: invoice.revenueHead.name,
        description: invoice.revenueHead.name,
        quantity: 1,
        price: invoice.amount,
      },
    ],
  };

  const navigateToExternalURL = (url: string) => {
    window.location.href = url;
  };

  const handlePayButtonClick = () => {
    const payload = {
      email: state.email,
      amount: calcGrandTotal().toString(),
    };

    const paymentPayload = (res: PaymentResponseData) => ({
      invoiceNumber: invoice.id,
      transactionRef: res.data.reference,
      amount: calcGrandTotal().toString(),
      paymentMethod: 'card',
      tenants: [
        {
          tenant: 'benue',
          roles: 'user',
        },
      ],
    });

    payInvoice(payload)
      .then((res) => {
        console.log(res);
        createPayment(paymentPayload(res));
        navigateToExternalURL(res.data.authorization_url);
        setLoading(false);
      })
      .catch((e) => notificationController.error({ message: e.message }))
      .finally(() => setLoading(false));
  };

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const calcTaxAmount = (c: number): number => {
    return c * (state.taxRate / 100);
  };

  const calcLineItemsTotal = (): number => {
    return state.lineItems.reduce((prev, cur) => prev + cur.quantity * cur.price, 0);
  };

  const calcTaxTotal = (): number => {
    return calcLineItemsTotal() * (state.taxRate / 100);
  };

  const calcGrandTotal = (): number => {
    return calcLineItemsTotal() + calcTaxTotal();
  };

  return (
    <StyledInvoice>
      <Brand>
        <img src={logo} alt="Logo" className="logo" />
      </Brand>
      <Addresses>
        <div>
          <strong>Autowave Group</strong>
          <br />
          123 Kensington Ave
          <br />
          Lafia, Nigeria
          <br />
          +234 (0) 801-2345-555
        </div>
        <div>
          <ValueTable>
            <Row>
              <div className="label">Customer #</div>
              <div>{state.profileCode}</div>
            </Row>
            <Row>
              <div className="label">Invoice #</div>
              <div>{state.invoiceNumber}</div>
            </Row>
            <Row>
              <div className="label">Date</div>
              <div>{state.createdAt}</div>
            </Row>
            <Row>
              <div className="label">Due Date</div>
              <div>{state.dueDate}</div>
            </Row>
          </ValueTable>
        </div>
      </Addresses>
      <h2>Invoice</h2>
      <LineItems items={state.lineItems} currencyFormatter={formatCurrency} />
      <TotalContainer>
        <div></div>
        <div>
          <ValueTable>
            <Row>
              <div className="label">Subtotal</div>
              <div className="currency">{formatCurrency(calcLineItemsTotal())}</div>
            </Row>
            <Row>
              <div className="label">Charge ({state.taxRate}%)</div>
              <div className="currency">{formatCurrency(calcTaxTotal())}</div>
            </Row>
            <Row>
              <div className="label">Total Due</div>
              <div className="currency">{formatCurrency(calcGrandTotal())}</div>
            </Row>
          </ValueTable>
        </div>
      </TotalContainer>
      <Footer>
        <Pay>
          <button className="payNow" onClick={handlePayButtonClick}>
            Pay Now
          </button>
        </Pay>

        <div className="closing">
          <div>Thank you for paying your tax</div>
        </div>
      </Footer>
    </StyledInvoice>
  );
};

export default Invoice;
