import React from 'react';
import { useTranslation } from 'react-i18next';
import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';

export const CategoryItem: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BaseButtonsForm.Item name="category" label={t('profile.nav.personalInfo.category')}>
      <BaseSelect>
        <Option value="individual">
          <BaseSpace align="center">{t('public.nav.invoice.buttons.individual')}</BaseSpace>
        </Option>
        <Option value="corporate">
          <BaseSpace align="center">{t('public.nav.invoice.buttons.corporate')}</BaseSpace>
        </Option>
        <Option value="agency">
          <BaseSpace align="center">{t('public.nav.invoice.buttons.agency')}</BaseSpace>
        </Option>
      </BaseSelect>
    </BaseButtonsForm.Item>
  );
};
