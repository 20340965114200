import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

export const LgaItem: React.FC<{ readOnly?: boolean }> = ({ readOnly }) => {
  const { t } = useTranslation();

  return (
    <BaseButtonsForm.Item name="LgaOfResidence" label="Local Government Area">
      <BaseInput readOnly={!!readOnly} />
    </BaseButtonsForm.Item>
  );
};
