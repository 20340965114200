import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import * as S from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import { Link } from 'react-router-dom';

const PublicInfoPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('public.nav.home.title')}</PageTitle>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={24} xl={24}>
          <BaseCard id="step-form" padding="1.25rem">
            <S.Card title={t('public.nav.home.welcome')}>
              <Link to={'pay-invoice'}>
                <BaseButton type="primary">{t('public.nav.home.buttons.payment')}</BaseButton>
              </Link>
              <Link to={'generate-invoices'}>
                <BaseButton type="default">{t('public.nav.home.buttons.invoice')}</BaseButton>
              </Link>
              <Link to={'generate-tin'}>
                <BaseButton type="text">{t('public.nav.home.buttons.tin')}</BaseButton>
              </Link>
              <Link to={'register'}>
                <BaseButton type="link">{t('public.nav.home.buttons.register')}</BaseButton>
              </Link>
              <Link to={'login'}>
                <BaseButton type="link">{t('public.nav.home.buttons.login')}</BaseButton>
              </Link>
            </S.Card>
          </BaseCard>
        </BaseCol>
      </BaseRow>
    </>
  );
};

export default PublicInfoPage;
