import React from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SiderLogo } from '../SiderLogo';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const DesktopHeader: React.FC = ({}) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);

  return (
    <BaseRow justify="end" align="middle">
      {user && (
        <S.ProfileColumn xl={8} xxl={7}>
          <BaseRow align="middle" justify="end" gutter={[5, 5]}>
            <BaseCol>
              <ProfileDropdown />
            </BaseCol>
          </BaseRow>
        </S.ProfileColumn>
      )}
    </BaseRow>
  );
};
