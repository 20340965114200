import React, { Component } from 'react';
import LineItem, { LineItemProps } from './LineItem';
import styled from 'styled-components';
import { baseInvoice } from './_Invoice';

interface LineItemsProps {
  items: LineItemProps[];
  currencyFormatter: (amount: number) => string;
}

const LineItemsContainer = styled.div`
  ${baseInvoice}
  margin-bottom: var(--rhythm) * 4;
  .addItem {
    padding-top: var(--rhythm);
    button {
      @extend %flex-center;
      background: none;
      padding: var(--rhythm) / 2;
      border: 1px solid $addColor;
      color: $addColor;
      border-radius: var(--borderRadius);
      float: right;
      .addIcon {
        color: $addColor;
        margin-right: var(--rhythm) / 4;
      }
    }
  }
`;

const GridTable = styled.div`
  margin: 0 auto;
  border: 1px solid var(--tableBorderColor);
  border-radius: var(--borderRadius);
  overflow: hidden;
  display: grid;
  & > .row {
    display: grid;
    grid-template-columns: 1fr repeat(2, 5fr) 2fr repeat(2, 3fr) 2fr;
    border-bottom: 1px solid var(--tableBorderColor);
    &:last-of-type {
      border-bottom: none;
    }
    &.header > div {
      text-align: center;
      font-weight: bold;
      background: $boxBgColor;
    }
    &.editable > div {
      padding: calc(var(--rhythm) / 2);
    }
    & > div {
      var(--flex-center);
      padding: calc((--rhythm)/2);
      border-left: 1px solid var(--tableBorderColor);
      width: 100%;
      line-height: var(--rhythm);
      min-width: var(--rhythm);
      &:first-of-type {
        padding: calc((--rhythm)/2) 0;
        border-left: none;
      }
      &:last-of-type {
        padding: calc((--rhythm)/2) 0;
      }
      &:first-of-type,
      &:nth-child(4),
      &:last-of-type {
        text-align: center;
      }
      & > .deleteItem {
        var(--flex-center);
        color: $removeColor;
        background: none;
        border: 0;
        padding: 0;
        cursor: pointer;
      }
    }
  }
  .currency > input[type='number'] {
    max-width: var(--rhythm) * 4;
  }
`;

class LineItems extends Component<LineItemsProps> {
  render() {
    const { items, ...functions } = this.props;

    return (
      <form>
        <LineItemsContainer>
          <GridTable>
            <div className={`row header`}>
              <div>#</div>
              <div>Item</div>
              <div>Description</div>
              <div>Qty</div>
              <div>Price</div>
              <div>Total</div>
            </div>
            {items.map((item, i) => (
              <LineItem
                key={item?.id && item?.id + i}
                index={i}
                name={item.name}
                description={item.description}
                quantity={item.quantity}
                price={item.price}
                {...functions}
              />
            ))}
          </GridTable>
        </LineItemsContainer>
      </form>
    );
  }
}

export default LineItems;
