import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { FullNameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/FullNameItem/FullNameItem';
import { NicknameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/NicknameItem/NicknameItem';
import { SexItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/SexItem/SexItem';
import { BirthdayItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/BirthdayItem/BirthdayItem';
import { LanguageItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LanguageItem/LanguageItem';
import { PhoneItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/PhoneItem/PhoneItem';
import { EmailItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/EmailItem/EmailItem';
import { CountriesItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/CountriesItem/CountriesItem';
import { CitiesItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/CitiesItem/CitiesItem';
import { ZipcodeItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/ZipcodeItem/ZipcodeItem';
import { AddressItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/AddressItem/AddressItem';
import { WebsiteItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/WebsiteItem/WebsiteItem';
import { SocialLinksItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/SocialLinksItem/SocialLinksItem';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Dates } from '@app/constants/Dates';
import { notificationController } from '@app/controllers/notificationController';
import { PaymentCard } from '@app/interfaces/interfaces';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { TinItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/TinItem/TinItem';
import { AddressNewItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/AddressNewItem/AddressNewItem';
import { StatesItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/StatesItem/StatesItem';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { LgasItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LgasItem/LgasItem';
import { ConfirmItemPassword } from '@app/components/profile/profileCard/profileFormNav/nav/SecuritySettings/passwordForm/ConfirmPasswordItem/ConfirmPasswordItem';
import { PasswordItem } from '@app/components/profile/profileCard/profileFormNav/nav/SecuritySettings/passwordForm/PasswordItem/PasswordItem';
import { State, City, IState, ICity } from 'country-state-city';
import { CategoryItem } from './CategoryItem/CategoryItem';
import { AltPhoneItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/AltPhoneItem/AltPhoneItem';
import { UserModel } from '@app/domain/UserModel';

import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogin, doSignUp } from '@app/store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { SignUpRequest } from '@app/api/auth.api';
import { LgaItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LgaItem/LgaItem';

interface PersonalInfoFormValues {
  fullName: string;
  lgaOfResidence: string;
  stateOfResidence: string;
  stateOfOrigin: string;
  address: string;
  language?: string;
  phoneNumber: string;
  email: string;
  password: string;
  altPhoneNumber: string;
  tin: string;
  category: string;
}

const initialPersonalInfoValues: PersonalInfoFormValues = {
  fullName: '',
  language: undefined,
  lgaOfResidence: '',
  stateOfResidence: '',
  stateOfOrigin: '',
  phoneNumber: '',
  altPhoneNumber: '',
  email: '',
  address: '',
  category: '',
  tin: '',
  password: '',
};

export const Register: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  const [form] = BaseButtonsForm.useForm();

  const handleStateChange = (value: string) => {
    const countryCode = 'NG';
    const cityList = City.getCitiesOfState(countryCode, value);
    setCities(cityList);
  };

  useEffect(() => {
    const countryCode = 'NG';
    const statesList = State.getStatesOfCountry(countryCode);
    setStates(statesList);
  }, [setStates]);

  const { t } = useTranslation();

  const onFinish = (values: SignUpRequest | UserModel) => {
    // todo dispatch an action here
    setLoading(true);
    dispatch(doSignUp({ ...values, tenants: [{ tenant: 2, roles: 'user' }] }))
      .unwrap()
      .then(() => navigate('/public/login'))
      .catch((err) => {
        console.error(err);
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <BaseCard>
      <BaseButtonsForm
        form={form}
        name="payerProfile"
        loading={isLoading}
        initialValues={initialPersonalInfoValues}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
        onFieldsChange={() => setFieldsChanged(true)}
        onFinish={onFinish}
        footer={
          <BaseButtonsForm.Item>
            <BaseButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.proceed')}
            </BaseButton>
          </BaseButtonsForm.Item>
        }
      >
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
          <BaseCol xs={24} md={12}>
            <CategoryItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <TinItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <FullNameItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <PhoneItem />
          </BaseCol>
          {/* Category,  */}
          <BaseCol xs={24} md={12}>
            <AltPhoneItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <EmailItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <PasswordItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <ConfirmItemPassword />
          </BaseCol>

          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>{t('common.address')}</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <StatesItem states={states} nameTitle="stateOfOrigin" />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <StatesItem states={states} nameTitle="stateOfResidence" handleStateChange={handleStateChange} />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <LgaItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <AddressNewItem />
          </BaseCol>
        </BaseRow>
      </BaseButtonsForm>
    </BaseCard>
  );
};
