import React from 'react';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

export const FullNameItem: React.FC<{ readOnly?: boolean }> = ({ readOnly }) => {
  const { t } = useTranslation();

  return (
    <BaseButtonsForm.Item
      name="fullName"
      rules={[
        {
          required: true,
          message: t('profile.nav.register.requiredFullName'),
        },
      ]}
      label={t('common.fullName')}
    >
      <BaseInput readOnly={!!readOnly} />
    </BaseButtonsForm.Item>
  );
};
