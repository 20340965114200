import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import Invoice from '@app/components/public/Invoice/Invoice';

const InvoiceSummaryPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('profile.nav.register.title')}</PageTitle>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={24} xl={24}>
          <BaseCard id="register-form" title={t('profile.nav.register.title')} padding="1.25rem">
            <Invoice />
          </BaseCard>
        </BaseCol>
      </BaseRow>
    </>
  );
};

export default InvoiceSummaryPage;
