import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import Invoice from '@app/components/public/Invoice/Invoice';
import { InvoiceData, getInvoicesData } from '@app/api/invoices.api';
import { useLocation } from 'react-router-dom';

const InvoicePaymentPage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const invoiceData = location.state;
  // console.log(location.state);
  const [invoice, setInvoice] = useState<InvoiceData>();

  useEffect(() => {
    setInvoice(invoiceData);
  }, [invoiceData, setInvoice]);

  return (
    <>
      <PageTitle>{t('public.nav.payInvoice.title')}</PageTitle>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={24} xl={24}>
          <BaseCard id="step-form" title={t('public.nav.payInvoice.title')} padding="1.25rem">
            {invoice && <Invoice invoice={invoice} />}
          </BaseCard>
        </BaseCol>
      </BaseRow>
    </>
  );
};

export default InvoicePaymentPage;
