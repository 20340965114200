import React from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import * as S from './PhoneItem.styles';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';

interface PhoneItemsProps {
  required?: boolean;
  onClick?: () => void;
  verified?: boolean;
}

export const AltPhoneItem: React.FC<PhoneItemsProps> = ({ required, onClick, verified }) => {
  const { t } = useTranslation();

  return (
    <BaseButtonsForm.Item
      name="altPhoneNumber"
      $isSuccess={verified}
      $successText={t('profile.nav.personalInfo.verified')}
      label="Alternative Phone Number"
      rules={[{ len: 11 }]}
    >
      <BaseInput className="ant-input" onClick={onClick} />
    </BaseButtonsForm.Item>
  );
};
