import React from 'react';
import {
  DashboardOutlined,
  FormOutlined,
  LayoutOutlined,
  LineChartOutlined,
  TableOutlined,
  UserOutlined,
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.medical-dashboard',
    key: 'medical-dashboard',
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'public.nav.home.names.invoices',
    key: 'invoices',
    url: '/invoices',
    icon: <TableOutlined />,
  },
  // {
  //   title: 'public.nav.home.names.payments',
  //   key: 'payments',
  //   url: '/payments',
  //   icon: <TableOutlined />,
  // },
  // {
  //   title: 'common.charts',
  //   key: 'taxProfiles',
  //   url: '/charts',
  //   icon: <LineChartOutlined />,
  // },
  {
    title: 'public.nav.home.buttons.payment',
    key: 'payInvoice',
    url: '/public/pay-invoice',
    icon: <LineChartOutlined />,
  },
  {
    title: 'public.nav.home.buttons.invoice',
    key: 'generateInvoices',
    url: '/public/generate-invoices',
    icon: <LineChartOutlined />,
  },
  {
    title: 'public.nav.home.buttons.tin',
    key: 'generateTin',
    url: '/public/generate-tin',
    icon: <LineChartOutlined />,
  },
];
