import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { FullNameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/FullNameItem/FullNameItem';
import { NicknameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/NicknameItem/NicknameItem';
import { SexItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/SexItem/SexItem';
import { BirthdayItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/BirthdayItem/BirthdayItem';
import { LanguageItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LanguageItem/LanguageItem';
import { PhoneItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/PhoneItem/PhoneItem';
import { EmailItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/EmailItem/EmailItem';
import { CountriesItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/CountriesItem/CountriesItem';
import { CitiesItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/CitiesItem/CitiesItem';
import { ZipcodeItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/ZipcodeItem/ZipcodeItem';
import { AddressItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/AddressItem/AddressItem';
import { WebsiteItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/WebsiteItem/WebsiteItem';
import { SocialLinksItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/SocialLinksItem/SocialLinksItem';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Dates } from '@app/constants/Dates';
import { notificationController } from '@app/controllers/notificationController';
import { PaymentCard } from '@app/interfaces/interfaces';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { TinItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/TinItem/TinItem';
import { AddressNewItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/AddressNewItem/AddressNewItem';
import { StatesItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/StatesItem/StatesItem';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { LgasItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LgasItem/LgasItem';
import { Country, State, City, IState, ICity } from 'country-state-city';
import { TaxProfileRequest, TaxProfileResponse, createTaxProfile } from '@app/api/taxProfile.api';
import { useNavigate } from 'react-router-dom';
import { InvoiceRequestData, createInvoice } from '@app/api/invoices.api';
import { AmountItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/AmountItem/AmountItem';
import { RevenueItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/RevenueItem/RevenueItem';
import { omitBy } from 'lodash';
import { UserModel } from '@app/domain/UserModel';
import { LgaItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LgaItem/LgaItem';

interface PersonalInfoFormValues {
  fullName: string;
  stateOfResidence: string;
  LgaOfResidence: string;
  address: string;
  phoneNumber: string;
  email: string;
  tin: string | undefined;
  amount: string;
  revenueHead: number;
  mdas: number;
  category: string;
}

export const GenerateInvoice: React.FC<{ agency: number; revenue: number; category: string }> = ({
  agency,
  revenue,
  category,
}) => {
  const user = useAppSelector((state) => state.user.user) as UserModel;
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const navigate = useNavigate();

  const initialPersonalInfoValues: PersonalInfoFormValues = {
    fullName: user?.fullName,
    phoneNumber: user?.taxProfile.phoneNumber,
    email: user?.email,
    stateOfResidence: user?.taxProfile.stateOfResidence,
    LgaOfResidence: user?.taxProfile.lgaOfResidence,
    address: user?.taxProfile.address,
    tin: user?.taxProfile.tin,
    amount: '',
    revenueHead: revenue,
    mdas: agency,
    category: user?.taxProfile.category,
  };

  const [form] = BaseButtonsForm.useForm();

  const handleStateChange = (value: string) => {
    const countryCode = 'NG';
    const cityList = City.getCitiesOfState(countryCode, value);
    setCities(cityList);
  };

  useEffect(() => {
    const countryCode = 'NG';
    const statesList = State.getStatesOfCountry(countryCode);
    setStates(statesList);

    if (user) {
      setReadOnly(true);
      console.log(user);
      if (user.taxProfile.stateOfResidence) {
        const cityList = City.getCitiesOfState(countryCode, user.taxProfile.stateOfResidence);
        setCities(cityList);
      }
    }
  }, [readOnly, setStates, user]);

  const { t } = useTranslation();

  const onFinish = (values: InvoiceRequestData) => {
    // todo dispatch an action here
    const filteredValues = omitBy(values, (value: any) => value === '' || value === undefined) as InvoiceRequestData;
    // console.log(filteredValues);
    setLoading(true);
    createInvoice({
      ...filteredValues,
      revenueHead: revenue,
      category,
      // mdas: agency,
      // tenants: [{ tenant: 2, roles: 'user' }],
      tenant: 2,
    })
      .then((res) => {
        setLoading(false);
        setFieldsChanged(false);
        navigate('/public/print-invoice', { state: res });
      })
      .catch((e) => notificationController.error({ message: e.message }))
      .finally(() => setLoading(false));
  };

  return (
    <BaseCard>
      <BaseButtonsForm
        form={form}
        name="payerProfile"
        loading={isLoading}
        initialValues={initialPersonalInfoValues}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
        onFieldsChange={() => setFieldsChanged(true)}
        onFinish={onFinish}
        footer={
          <BaseButtonsForm.Item>
            <BaseButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.proceed')}
            </BaseButton>
          </BaseButtonsForm.Item>
        }
      >
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>{t('profile.nav.personalInfo.title')}</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <AmountItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <TinItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <FullNameItem readOnly={readOnly} />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <PhoneItem readOnly={readOnly} />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <EmailItem readOnly={readOnly} />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <StatesItem
              readOnly={readOnly}
              states={states}
              nameTitle="stateOfResidence"
              handleStateChange={handleStateChange}
            />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <LgaItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <AddressNewItem readOnly={readOnly} />
          </BaseCol>
        </BaseRow>
      </BaseButtonsForm>
    </BaseCard>
  );
};
