import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';

import PublicLayout from '@app/components/layouts/public/MainLayout/MainLayout';

import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import DashboardPage from '@app/pages/DashboardPages/DashboardPage';
import HomePage from '@app/pages/PublicPages/HomePage';
import InvoiceGenerationPage from '@app/pages/PublicPages/InvoiceGenerationPage';
import GenerateInvoicePage from '@app/pages/PublicPages/GenerateInvoicePage';
import PaymentPage from '@app/pages/PublicPages/PaymentPage';
import InvoiceConfirmationPage from '@app/pages/PublicPages/InvoiceConfirmationPage';
import RegisterPage from '@app/pages/PublicPages/RegisterPage';
import InvoiceSummaryPage from '@app/pages/PublicPages/InvoiceSummaryPage';
import PublicLoginPage from '@app/pages/PublicPages/PublicLoginPage';
import InvoicePaymentPage from '@app/pages/PublicPages/InvoicePaymentPage';

const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const PaymentTablesPage = React.lazy(() => import('@app/pages/PaymentTablesPage'));
const InvoiceTablesPage = React.lazy(() => import('@app/pages/InvoiceTablesPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const StepFormsPage = React.lazy(() => import('@app/pages/StepFormsPage'));
const ValidationFormsPage = React.lazy(() => import('@app/pages/ValidationFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));

const GenerateTinPage = React.lazy(() => import('@app/pages/PublicPages/GenerateTinPage'));
const PayerProfilePage = React.lazy(() => import('@app/pages/PublicPages/PayerProfilePage'));

const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const DASHBOARD_PATH = '/';

const Dashboard = withLoading(DashboardPage);
const AdvancedForm = withLoading(AdvancedFormsPage);

const DataTables = withLoading(DataTablesPage);
const PaymentTables = withLoading(PaymentTablesPage);
const InvoiceTables = withLoading(InvoiceTablesPage);
const GenerateTin = withLoading(GenerateTinPage);
const InvoiceGeneration = withLoading(InvoiceGenerationPage);
const GenerateInvoice = withLoading(GenerateInvoicePage);
const InvoicePayment = withLoading(InvoicePaymentPage);
const PayerProfile = withLoading(PayerProfilePage);
const InvoiceConfirmation = withLoading(InvoiceConfirmationPage);
const Payment = withLoading(PaymentPage);
const InvoiceSummary = withLoading(InvoiceSummaryPage);
const PublicLogin = withLoading(PublicLoginPage);
const Register = withLoading(RegisterPage);
const Charts = withLoading(ChartsPage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<Dashboard />} />
          <Route path="forms">
            <Route path="advanced-forms" element={<AdvancedForm />} />
            <Route path="validation-forms" element={<ValidationFormsPage />} />
            <Route path="step-forms" element={<StepFormsPage />} />
          </Route>
          <Route path="data-tables" element={<DataTables />} />
          <Route path="payments" element={<PaymentTables />} />
          <Route path="invoices" element={<InvoiceTables />} />
          <Route path="charts" element={<Charts />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="payments" element={<Payments />} />
          </Route>
        </Route>

        <Route path="/public" element={<PublicLayout />}>
          <Route index element={<HomePage />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<PublicLogin />} />
          <Route path="generate-tin" element={<GenerateTin />} />
          <Route path="generate-invoices" element={<InvoiceGeneration />} />
          <Route path="invoice-details" element={<GenerateInvoice />} />
          <Route path="print-invoice" element={<InvoicePayment />} />
          <Route path="payer-profile" element={<PayerProfile />} />
          <Route path="confirm-invoice" element={<InvoiceConfirmation />} />
          <Route path="pay-invoice" element={<Payment />} />
          <Route path="invoice-summary" element={<InvoiceSummary />} />
        </Route>

        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
