import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartCustomLegend } from '../../common/charts/PieChartCustomLegend';
import { healthChartData } from 'constants/healthChartData';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { InvoiceData, Pagination, getAllInvoicesData } from '@app/api/invoices.api';
import { useMounted } from '@app/hooks/useMounted';
import { notificationController } from '@app/controllers/notificationController';

export const HealthCard: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<InvoiceData[] | undefined>();
  const [chartData, setChartData] = useState<{ name: string; description: string; value: number }[]>([]);
  const [legendData, setLegendData] = useState([]);

  const { isMounted } = useMounted();

  const fetch = useCallback(() => {
    getAllInvoicesData()
      .then((res) => {
        const data = res.docs.map((doc) => ({
          ...doc,
          dueDate: new Date(doc.dueDate).toDateString(),
          createdAt: new Date(doc.createdAt).toDateString(),
          revenue: doc.revenueHead.name,
        }));

        if (isMounted.current) {
          setData(data);
        }
      })
      .catch((e) => notificationController.error({ message: e.message }));
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (data && data !== undefined) {
      const totalSum = data.reduce((sum, item) => sum + parseInt(item.amount), 0);

      setChartData(
        data.map((item) => ({
          name: item.revenueHead.name,
          description: item.revenueHead.name,
          value: parseInt(item.amount),
        })) as any,
      );

      setLegendData(chartData.map((item) => ({ ...item, value: `${(item.value / totalSum) * 100}%` })) as any);
    }
  }, [chartData, data]);

  return (
    <BaseCard title={t('medical-dashboard.stats.title')} padding={'0 1.25rem 1.875rem'}>
      <PieChartCustomLegend
        name={t('medical-dashboard.stats.title')}
        chartData={chartData as any}
        legendData={legendData as any}
        height="300px"
      />
    </BaseCard>
  );
};
