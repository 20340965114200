import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

export const AmountItem: React.FC = () => {
  return (
    <BaseButtonsForm.Item
      name={`amount`}
      rules={[
        {
          required: true,
          message: 'Amount is required',
        },
      ]}
      label={`Amount`}
    >
      <BaseInput />
    </BaseButtonsForm.Item>
  );
};
