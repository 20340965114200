import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import countryList from 'country-list';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import * as S from './LgasItem.styles';

const countries = countryList.getData();

const selectOptions = (states: any[]) =>
  states.map((state) => (
    <Option key={state.name} value={state.isoCode}>
      <S.CountriesSpace align="center">{state.name}</S.CountriesSpace>
    </Option>
  ));

export const LgasItem: React.FC<{ cities?: any }> = ({ cities }) => {
  const { t } = useTranslation();

  return (
    <BaseButtonsForm.Item
      name="LgaOfResidence"
      rules={[
        {
          required: true,
          message: t('profile.nav.register.requiredLga'),
        },
      ]}
      label={t('common.lga')}
    >
      <BaseSelect
        showSearch
        disabled={cities.length === 0}
        placeholder={t('profile.nav.register.requiredLga')}
        filterOption={(input, option) => option?.value.toLowerCase().includes(input.toLowerCase())}
      >
        {cities && selectOptions(cities)}
      </BaseSelect>
    </BaseButtonsForm.Item>
  );
};
