import { httpApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';

export interface RevenueData {
  name: string;
  id: number;
}

export interface RevenueResponseData {
  docs: RevenueData[];
}

export const getRevenuesData = (): Promise<RevenueData[] | undefined> => {
  return httpApi.get<RevenueResponseData>('revenue-heads').then(({ data }) => data.docs);
};
